// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $client-primary: mat.define-palette(mat.$indigo-palette);
// $client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $client-theme: mat.define-light-theme((
//   color: (
//     primary: $client-primary,
//     accent: $client-accent,
//     warn: $client-warn,
//   )
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($client-theme);

/* You can add global styles to this file, and also import other style files */
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-display: swap
}

.mat-icon {
  font-family: 'Material Icons' !important;
}

.settings-content {
  min-height: 700px;

  .mat-datepicker-toggle {
    background: var(--settings-select-background-color) !important;
    border-top: 1px solid var(--settings-select-border-color) !important;
    border-bottom: 1px solid var(--settings-select-border-color) !important;
    border-right: 1px solid var(--settings-select-border-color) !important;
    border-radius: 0px 8px 8px 0px;
  }

  .mat-icon-button {
    background-color: var(--settings-select-background-color) !important;
  }

  .mat-button-wrapper {
    color: var(--ql-color) !important;
  }
}

mat-button-toggle-group {
  background-color: var(--sidebar-background);
  // border: 1px solid var(--border-primary) !important;
  border-radius: 8px !important;
  // margin-left: 20px;
  color: var(--item-title);
  box-shadow: 0px 0px 0px 0.963px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner-border, rgba(16, 24, 40, 0.18)) inset, 0px -1.926px 0px 0px var(--Colors-Effects-Shadows-shadow-skeumorphic-inner, rgba(16, 24, 40, 0.05)) inset, 0px 0.963px 1.926px 0px var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05)) !important;

  .mat-button-toggle-checked {
    background-color: var(--blue) !important;

    .mat-button-toggle-label-content {
      color: #f1f9fe !important;
    }
  }

  .mat-button-toggle-appearance-standard {
    border: 0.5px solid var(--border-primary) !important;
    display: flex !important;
    align-items: center;
  }

  .mat-button-toggle-label-content {
    display: flex !important;
    align-items: center;
    gap: 8px;
    color: var(--title-color);
    font-size: 14px;
    font-weight: 600;
    padding: 0 5px !important;

    .mat-pseudo-checkbox-checked {
      display: none !important;
    }
  }
}

mat-button-toggle {
  padding: 10px;

  &:first-child {
    border-right: 1px solid #f5f5f7;
  }

  &:last-child {
    border-left: 1px solid #f5f5f7;
  }
}

.pop-ups-button,
.pop-ups-cancel-button {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  border-radius: 9px;
}

.pop-ups-button {
  padding: 10px 32px;
  background: #286286;
  color: #ffffff;
  border: none;
  outline: none;
}

.pop-ups-cancel-button {
  padding: 10px 18px;
  background: var(--dialog-cancel-button-background-color);
  border: 1px solid #286286;
  color: #286286;
}

.container-icon,
.container-icon-active {
  width: 43px;
  height: 43px;
  border-radius: 5px;
}

.container-icon {
  background-color: var(--icon-background-color);
}

.container-icon-active {
  background-color: var(--icon-background-color-active);
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}

.dashboard-card,
.dashboard-card-activated {
  border-radius: 15px !important;
  padding: 20px !important;
}

.dashboard-card .mat-expansion-panel-header,
.dashboard-card-activated .mat-expansion-panel-header {
  padding: 0px !important;
}

.dashboard-card {
  background-color: var(--dashboard-card-background-color) !important;

  .mat-expansion-indicator {
    color: var(--title-color) !important;
  }
}

.dashboard-card-activated {
  background-color: var(--menu-item-background-color-highlighted) !important;
  border: 1px solid var(--menu-item-line-color-highlighted) !important;

  .mat-expansion-indicator {
    color: var(--highlighted-font) !important;
  }
}

.dashboard-card-item .dashboard-card-item-activated {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-size: 17px;
  line-height: 21px;
}

.dashboard-card-item {
  font-weight: 400;
  color: var(--title-color);
}

.dashboard-card-item-activated {
  font-weight: 600;
  color: var(--highlighted-font);
}

.search-input,
.search-input::placeholder {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.search-input {
  width: 100%;
  background: var(--search-input-background-color);
  border-radius: 69px;
  outline: none;
  border: none;
  padding: 15px 50px;
  color: var(--title-color);

  &::placeholder {
    color: #bfc6cc;
  }
}

.search-icon {
  position: absolute;
  top: 19px;
  left: 18px;
  cursor: pointer;
}

.submenu {
  margin-top: 15px;

  .submenu-item {
    padding-top: 15px;
    padding-left: 15px;
  }
}


// & phone number input customization
.register-page {
  .iti {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    border: 1px solid #e5e5e5 !important;
    background: #fefefe !important;
    border-radius: 10px !important;

    .form-control {
      width: 100% !important;
      outline: none !important;
      padding: 15px 50px !important;

      &::placeholder {
        font-family: "DM Sans", sans-serif !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 30px !important;
        color: #9e9e9e !important;
      }
    }

    .iti__selected-flag {
      width: 100px !important;
      padding: 18px 0px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e5e5e5 !important;
    }
  }
}

.mat-datepicker-popup {
  --background-calendar-color: #f5f8ff;
  --title-color: #333333;
  margin-top: 10px;
  border-radius: 8px;
  background-color: var(--background-calendar-color) !important;
  border: 1px solid #286286;
  font-family: "DM Sans", sans-serif;
  font-size: 12px !important;
  color: var(--title-color) !important;
}

.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-content .mat-calendar-next-button {
  width: 40px !important;
  height: 40px !important;
}

.mat-calendar-body-cell-content {
  width: 30px !important;
  height: 30px !important;
  border: none !important;
}



.iti {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-items: center;

  .form-control {
    border-radius: 10px !important;
    margin-left: -50px !important;
    font-family: "DM Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: var(--settings-select-font-color) !important;
    width: 100% !important;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;

    &::placeholder {
      font-family: "DM Sans", sans-serif !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 30px !important;
      color: #bfc6cc !important;
    }
  }

  .iti__selected-flag {
    padding: 15px 0 !important;
    display: inline-flex;
    align-items: center;
    color: var(--settings-select-font-color) !important;
    border-right: 1px solid var(--settings-select-border-color) !important;
  }
}

// & angular material elements customization

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
  /*click effect color change*/
  background-color: #286286 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #286286 !important;
  /*inner circle color change*/
}

.mat-radio-button.mat-accent .mat-radio-outer-circle {
  border-color: #286286 !important;
  /*outer ring color change*/
}

.mat-radio-button .mat-radio-label-content {
  font-family: "Outfit", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: var(--title-color) !important;
}

.mat-mdc-dialog-content {
  max-height: unset !important;
}

.mat-mdc-dialog-surface {
  background: var(--dialog-background-color) !important;
  border: 1px solid var(--dialog-border-color) !important;
  box-shadow: var(--dialog-box-shadow) !important;
  border-radius: 15px !important;
  padding: 0px !important;
  height: unset !important;
}

@mixin pagination-font-style {
  font-family: "Outfit", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 17px !important;
}

.pagination {
  .ngx-pagination a {
    @include pagination-font-style;
    color: var(--title-color) !important;
  }

  .ngx-pagination .current {
    @include pagination-font-style;
    background: var(--blue) !important;
    border-radius: 50%;
  }

  .ngx-pagination a:hover {
    background: var(--sidebar-item-background) !important;
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: 0px;
}

// mdc button
.mat-mdc-button .mdc-button__label {
  display: flex;
  gap: 10px;
}

@mixin snackbar-font-style {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}

.mat-mdc-snack-bar-container {
  border-radius: 12px !important;
  background: #F9FCFF;
  color: #1a1a1a;
  @include snackbar-font-style;

  button {
    background-color: #f5f8ff;
    @include snackbar-font-style;
  }
}

.registerSelectPanelClass.mat-mdc-select-panel {
  margin-top: 25px;
  margin-left: -30px;
  width: 100% !important;
  background: #f5f8ff;
  border: 1px solid #ccdfe7;
  border-radius: 8px;

  .mdc-list-item__primary-text {
    padding: 10px !important;
    font-family: "DM Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.customerSupportPanelClass.mat-mdc-select-panel {
  margin-top: 20px;
  // margin-left: -30px;
  background: #fbfcff;
  border: 1px solid rgba(40, 98, 134, 0.24);
  border-radius: 10px;

  .mdc-list-item__primary-text {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #01102c;
  }

  mat-option[aria-selected="true"] {
    background: #e5ebed !important;
    border: 1px solid #286286 !important;
  }
}

.mat-icon-button {
  background-color: #f5f8ff;
}

// container type select

#timer,
#reminder,
#frequency {
  .mat-mdc-select-arrow-wrapper {
    opacity: 0;
  }
}

.typeSelectPanelClass.mat-mdc-select-panel {
  margin-top: 20px;
  margin-left: -17px;
  background: var(--select-popup);
  padding: 7px;
  border-radius: 6px;
  // display: inline-table;
  // height: 400px !important;

  mat-option {
    font-family: "Outfit", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 5px;
    color: var(--item-title);
  }

  mat-option[aria-selected="true"],
  mat-option:hover {
    background: var(--select-border) !important;
    border-radius: 7px !important;
  }

  .other-type {
    color: #eaac18 !important;
    font-family: "Outfit", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration-line: underline;
  }
}

.mat-select-panel {
  position: absolute !important;
  z-index: 2;
}

.other-content {
  gap: 20px;
  margin-top: 27px;
  position: relative;
  z-index: 1;
  transition: margin-top 0.3s;
}

// +++++++

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  --mat-text-button-state-layer-color: transparent !important;
  --mat-text-button-ripple-color: transparent !important;

}

.questionPanelClass.mat-mdc-select-panel {
  margin-top: 25px;
  background: var(--menu-item-background-color-highlighted);
  border: 1px solid var(--dialog-input-border-color);
  border-radius: 8px;

  .mdc-list-item__primary-text {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 50px !important;
    color: var(--title-color) !important;
  }
}

.settingsPanelClass.mat-mdc-select-panel {
  background: var(--background-color-secondary);
  border: 1px solid var(--settings-select-border-color);
  border-radius: 10px;
  margin-top: 16px;
  margin-left: -16px;
  font-family: "Outfit", sans-serif;
}

.settingsPanelClassProfile.mat-mdc-select-panel {
  background: var(--background-color);
  border: 1px solid var(--settings-select-border-color);
  border-radius: 10px;
  margin-top: 22px;
  font-family: "Outfit", sans-serif;
  margin-left: -12px;
  padding: 12px;
}

.mdc-list-item__primary-text {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--title-color) !important;
}

mat-option[aria-disabled="true"] {
  opacity: 0.5;
}

.frequencyPanelClass.mat-mdc-select-panel,
.reminderPanelClass.mat-mdc-select-panel,
.timerPanelClass.mat-mdc-select-panel {
  margin-top: 25px;
  background: var(--menu-item-background-color-highlighted);
  border: 1px solid var(--dialog-input-border-color);
  border-radius: 8px;

  .mdc-list-item__primary-text {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--title-color) !important;
  }
}

.mat-autocomplete-panel {
  margin-top: 5px;
  background: var(--menu-item-background-color-highlighted);
  border: 1px solid var(--dialog-input-border-color);
  border-radius: 8px !important;

  .mat-option-text {
    font-family: "DM Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: var(--title-color) !important;
    padding: 15px 0px !important;
  }
}

.dialog-select-text .mat-select-value,
.dialog-select-text .mat-select-arrow {
  font-family: "DM Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--dialog-select-color) !important;
}

.custom-mat-menu.mat-menu-panel {
  background: var(--menu-item-background-color-highlighted) !important;
  border: 1px solid rgba(152, 191, 201, 0.44);
}

.timer-option-select-text .mat-select-value,
.timer-option-select-text .mat-select-arrow {
  font-family: "DM Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--note-subtitle-color) !important;
}

.mat-mdc-tooltip {
  padding: 5px !important;
  margin-top: -0.5rem !important;
  background: #01102c1a;
  box-shadow: 0 4px 13px #01102c1a;
  border-radius: 8px;
  border: 1px solid var(--menu-item-line-color-highlighted) !important;
  font-family: "DM Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #286286 !important;
}

.toggle-tooltip {
  max-width: none !important;
  word-wrap: unset !important;
  background-color: var(--sidebar-background) !important;
}

.table-container {
  border-radius: 12px !important;

}

.wysiwyg-container {
  border-radius: 8px;

  .ql-container {
    background: var(--background-color-secondary) !important;
    color: var(--wysiwyg-color);
    border: 1px solid var(--border-secondary) !important;
    border-radius: 0px 0px 8px 8px;
  }

  .ql-stroke {
    stroke: var(--wysiwyg-color) !important;
  }

  .ql-fill {
    fill: var(--wysiwyg-color) !important;
  }

  .ql-picker-label {
    color: var(--wysiwyg-color) !important;
  }

  .ql-editor {
    overflow: unset;
  }

  .ql-editor::before {
    color: var(--wysiwyg-color) !important;
  }

  .ql-toolbar {
    border: 1px solid var(--border-secondary) !important;
    border-radius: 8px 8px 0px 0px;
    background: var(--background-color-secondary) !important;
  }
}

.ng-pristine {
  // width: 100%;
  border-radius: 7px;


  .ql-container {
    background: var(--background-color-secondary) !important;
    color: var(--wysiwyg-color);
    border: 1px solid var(--border-secondary) !important;
    border-radius: 0px 0px 8px 8px;
  }

  .ql-stroke {
    stroke: var(--wysiwyg-color) !important;
  }

  .ql-fill {
    fill: var(--wysiwyg-color) !important;
  }

  .ql-picker-label {
    color: var(--wysiwyg-color) !important;
  }

  .ql-editor {
    overflow: unset;
  }

  .ql-editor::before {
    color: var(--wysiwyg-color) !important;
  }


  .ql-toolbar {
    border-radius: 8px 8px 0 0;
    background: var(--background-color) !important;
  }
}

.notebook-wysiwyg-container {
  border-radius: 7px;


  .ql-container {
    background: var(--background-color) !important;
    color: var(--wysiwyg-color);
    border: none !important;
    min-height: 500px !important;
    border-radius: 0 0 7px 7px;
    border: 1px solid var(--border-primary) !important;

  }

  .ql-stroke {
    stroke: var(--wysiwyg-color) !important;
  }

  .ql-fill {
    fill: var(--wysiwyg-color) !important;
  }

  .ql-picker-label {
    color: var(--wysiwyg-color) !important;
  }

  .ql-editor {
    overflow: unset;
  }

  .ql-editor::before {
    color: var(--wysiwyg-color) !important;
  }

  .ql-toolbar {
    border: none !important;
    border-radius: 7px 7px 0 0;
    border: 1px solid var(--border-primary) !important;
    background-color: var(--sidebar-item-background) !important;
    margin-top: 15px;
  }
}

.full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.note-description {
  img {
    width: 250px !important;
  }
}

.mat-drawer-container {
  background-color: #FFFFFF !important;

}

.mat-drawer {
  background-color: #FFFFFF !important;
  border: 1px solid var(--border-primary) !important;
  width: 260px !important;
  padding: 30px 16px !important;
  position: relative;
  overflow-x: hidden;

  &.shrink {
    width: 80px !important; // Shrink width
    padding: 30px 14px !important;
  }

  &:not(.shrink) {
    width: 260px !important;
    padding: 30px 14px !important;
  }

  @media screen and (max-width: 960px) {
    width: 80px !important;
  }
}


@media screen and (max-width: 370px) {
  .mat-drawer {
    width: 100% !important;
  }
}

//progress bar
.mat-mdc-progress-bar {
  background-color: #ECE5F9;
  height: 32px;
  border-radius: 16px;
}

.mdc-linear-progress__bar {
  background-color: #286286 !important;
  height: 100% !important;
}

.mdc-linear-progress__bar-inner {
  border-color: #286286 !important;
}

.progress-label {
  color: #286286;
  /* Set the color of the label text */
  font-weight: bold;
  /* Set the font weight of the label text */
  text-align: center;
  /* Center the label text */
  line-height: 1;
  /* Set the line height of the label text */
  font-family: "DM Sans", sans-serif !important;
  font-style: normal !important;
}

.mat-stepper-vertical {
  width: 100%;

  @media screen and (max-width: 400px) {
    max-width: 360px;
  }

  .mat-step {
    width: 100%;
  }
}

.mat-stepper-horizontal-line,
.mat-stepper-vertical-line {
  color: #ccdfe7 !important;
}

.mat-vertical-content-container {
  border-left: 1px solid #ccdfe7 !important;
  margin-left: 15px !important;
}

.mat-step-header.mat-vertical-stepper-header {
  height: inherit;
  pointer-events: none !important;
}

.mat-step-label,
.mat-step-icon,
.opaque {
  opacity: 0.5;
}

.mat-step-label-selected,
.mat-step-icon-selected {
  opacity: 1;
}

.mat-step-icon {
  background-color: var(--background-color) !important;
  font-family: "Outfit", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #286286 !important;
  padding: 5px !important;
  border: 1px solid #286286 !important;
}

.mat-mdc-progress-spinner {
  stroke: var(--title-color) !important;
  width: 100px;
}

.my-containers-content {

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: var(--table-not-found-color) !important;
    width: 80px;
  }
}

.mdc-tab__text-label {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #286286;
  padding-bottom: 15px;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(40, 98, 134, 0.17);
  color: #286286;
  padding: 15px 15px 0px 15px;

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border: 2px solid #286286;
  }
}

.mat-mdc-tab-label-container {
  border: none !important;
}

.download-page {
  .mat-mdc-tab[aria-selected="false"] {
    color: rgba(255, 255, 255, 0.6) !important;
  }

  .mdc-tab__text-label {
    padding-bottom: 15px;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  }

  .mdc-tab--active {
    .mdc-tab__text-label {
      font-family: "DM Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff !important;
    }
  }

  .cdk-global-overlay-wrapper {
    overflow: auto;
  }

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background-color: #0C111D;
    opacity: 0.7;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border: 2px solid #eaac18 !important;
  }

  .mat-mdc-tab-header {
    border: 1px solid #eaac18 !important;
    padding: 15px 15px 0px 15px;
    border-radius: 12px;
  }
}

.sharing-center-tabs {
  .mat-mdc-tab-header {
    border-bottom: 0px solid var(--blue--yellow) !important;
    color: #E4E7EC !important;
    // padding: 0px !important;

    .mat-mdc-tab .mdc-tab-indicator__content--underline {
      border: 1.5px solid var(--blue--yellow) !important;
    }
  }

  // .mat-mdc-tab[aria-selected="false"] {
  //   color: var(--blue--yellow) !important;
  // }

  .mdc-tab__text-label {
    // padding-bottom: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #667085 !important;
  }

  .mat-mdc-tab-header-pagination-chevron {
    border: 2px solid var(--blue--yellow) !important;
    color: var(--blue--yellow) !important;
  }

  .mat-mdc-tab-label-container {
    border-bottom: 1px solid var(--border-primary) !important;
  }

  .mdc-tab--active {
    .mdc-tab__text-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--blue--yellow) !important;
    }
  }

  // .cdk-global-overlay-wrapper {
  //   overflow: auto;
  // }

  // .cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
  //   background-color: #0C111D;
  //   opacity: 0.7;
  // }

  // .mat-mdc-tab .mdc-tab-indicator__content--underline {
  //   border: 2px solid #eaac18 !important;
  // }

  // .mat-mdc-tab-header {
  //   border: 1px solid #eaac18 !important;
  //   padding: 15px 15px 0px 15px;
  //   border-radius: 12px;
  // }
}

.mat-mdc-select-arrow {
  color: var(--mat-mdc-select-arrow-color) !important;
  transform: translate(-60%, -50%) !important;
  margin-top: 8px !important;
}

.mat-mdc-tab-header-pagination-chevron {
  border: var(--title-color) !important;
}

.iti__country {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  outline: none;
  color: var(--title-color) !important;
  background: var(--background-calendar-color) !important;
}

.mat-mdc-menu-content {
  width: 120px !important;
  padding: 0 25px !important;
  border-radius: 8px !important;
  background-color: var(--dialog-background) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  text-decoration: none !important;
  font-size: medium !important;
  font-weight: bold;
  font-family: "DM Sans", sans-serif !important;
  color: var(--title-color) !important;
}

.error {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #d92d20;
}

.password-errors {
  border-radius: 10px;
  background-color: #d92c201a;
  position: relative;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #d92d20;
}

.password-errors::after {
  content: "";
  width: max-content;
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f44336c6 transparent;
}

.contact-field-errors {
  border-radius: 10px;
  background-color: rgba(217, 44, 32, 0.65);
  position: relative;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
}

.contact-field-errors::after {
  content: "";
  width: max-content;
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #f44336c6 transparent;
}

::ng-deep .cdk-visually-hidden {
  display: none !important;
}

.notebook-wysiwyg-container,
.markdown-container {
  img {
    width: 100%;
  }

  blockquote {
    border-left: 3px solid var(--blockquote-border);
    padding-left: 12px;
    color: var(--blockquote-color);
  }
}

.cancel-stepper,
.next-stepper {
  padding: 15px 50px;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  border-radius: 8px;
}

.cancel-stepper {
  padding: 12px;
  width: 130px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset, 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  outline: none;
}

.next-stepper {
  padding: 12px;
  width: 130px;
  border-radius: 8px;
  background: #286286;
  box-shadow: 0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset, 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  outline: none;
  border: 1px solid #286286;
}







.stepper-container {
  margin: 50px 0;
}

.stepper-content {
  width: 100%;
  max-width: 1000px;
}

.menu-item.active {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.14) 100%);
  box-shadow: 0px 3px 0px 0px #eaac18;
  height: 100%;
}

.pricing-carousel {
  padding: 16px;

  .owl-item,
  .ng-trigger {
    display: flex;
    justify-content: center;
  }

  .owl-dot span {
    width: 13px !important;
    height: 13px !important;
    background: #f0f0f0 !important;
  }

  @media screen and (max-width: 1280px) {
    .owl-dot.active span {
      background: #eaac18 !important;
    }
  }
}

.mat-mdc-chip.mdc-evolution-chip {
  border-radius: 6px !important;
  border: 1px solid #D2E1E7 !important;
  background: var(--select-field) !important;
  padding: 5px;
  font-family: 'Outfit', sans-serif;
  color: var(--input-color);
}

.mdc-switch {
  height: 25px !important;
  width: 50px !important;
  height: 30px !important;
  width: 60px !important;
  border-radius: 50px !important;
  padding: 5px !important;
}

.mdc-switch__track {
  width: 20px !important;
  height: 20px !important;
  border-radius: 20px !important;
  position: absolute !important;
  box-shadow: 0px 1px 3px 0px var(--Colors-Effects-Shadows-shadow-sm_01, rgba(16, 24, 40, 0.10)), 0px 1px 2px 0px var(--Colors-Effects-Shadows-shadow-sm_02, rgba(16, 24, 40, 0.06));
}

.mdc-switch__track::before {
  background-color: #fff !important;
  border-color: #fff !important;
}

.mdc-switch__track::after {
  background-color: #fff !important;
  border-color: #fff !important;
}

.mdc-switch--selected {
  background-color: var(--blue) !important;

  .mdc-switch__track {
    right: 4px !important;
    background-color: #fff !important;
  }
}


.mdc-switch--unselected {
  background-color: #F2F4F7 !important;

  .mdc-switch__track {
    left: 4px !important;
    background-color: #fff !important;
  }

  background-color: #28628650 !important;
  border-color: #28628650 !important;
}

.mdc-switch__track::after {
  background-color: #286286 !important;
  border-color: #286286 !important;
}

.mdc-switch--selected {
  background-color: #E3E9F0 !important;

  .mdc-switch__track {
    right: 10px !important;
  }
}

.mdc-switch--unselected {
  background-color: #E3E9F080 !important;

  .mdc-switch__track {
    left: 10px !important
  }
}

@media screen and (max-width: 599px) {
  .full-width-button {
    width: 100%;
  }
}